<template lang="pug">
  Form(:title="$t('reset_password.title')")
    template(v-slot:fields)
      .reset-password-hint.m-t-16 {{ $t("reset_password.hint") }}
      .d-flex.flex-row
        .form-item
          .form-item-label.d-flex.align-items-center {{ $t("reset_password.account_id") }}
        .form-item
          .form-item-input.d-flex.align-items-center
            BFormInput(
              type="text"
              v-model="account_id"
              name="account_id"
              :placeholder="$t('reset_password.placeholder.account_id')"
            )
      BAlert.m-t-14(
        :show="isAlertShown"
        :variant="alertVariant"
      )
        li.alert-tip(v-for="msg in alertMessages") {{ msg }}
    template(v-slot:link)
      RouterLink.log-in(:to="{ name: 'SignIn' }") {{ $t("sign_in.title") }}
    template(v-slot:button)
      AppButton.btn-confirm(
        title="reset_password.confirm_btn"
        @click="sendResetEmail"
      )
</template>

<script>
  // mixins
  import withValidations from "@/mixins/withValidations"

  // misc
  import { api } from "@/config"
  import { AUTH_LAYOUT, RESET_PASSWORD_REDIRECT_TIMEOUT } from "@/config/constants"

  const validationsMixin = withValidations(({ required }) => ({
    account_id: {
      required
    }
  }))

  export default {
    layout: AUTH_LAYOUT,

    components: {
      Form: () => import("@/components/Auth/Form"),
      AppButton: () => import("@/components/elements/AppButton")
    },

    mixins: [validationsMixin],

    data() {
      return {
        account_id: "",
        alertMessages: [],
        defaultErrors: [this.$t("reset_password.validations.invalid_account_id")],
        isAlertShown: false,
        alertVariant: "danger"
      }
    },

    methods: {
      showErrorAlert(errors) {
        this.alertMessages = errors || this.defaultErrors
        this.alertVariant = "danger"
        this.isAlertShown = true
      },
      showSuccessAlert() {
        this.alertMessages = [this.$t("reset_password.message_after_sent")]
        this.alertVariant = "success"
        this.isAlertShown = true
      },

      sendResetEmail() {
        this.validateAttributes()
        if (!this.isValid) {
          this.showErrorAlert()
          return
        }

        const params = {
          account_id: this.account_id
        }
        api
          .post("/accounts/users/reset_password", params)
          .then(() => {
            this.showSuccessAlert()

            setTimeout(() => {
              this.$router.push({ name: "SignIn" })
            }, RESET_PASSWORD_REDIRECT_TIMEOUT)
          })
          .catch(({ response }) => {
            switch (response.status) {
              case 404:
                this.showErrorAlert([this.$t("reset_password.validations.user_not_found")])
                break
              case 400:
                this.showErrorAlert([response.data.common])
                break
              case 422:
                this.showErrorAlert([response.data.data])
                break
            }
          })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  .reset-password-hint
    font-size: 0.8rem
    color: $default-gray

  .form-item
    width: 100%
    display: flex
    flex-direction: column
    margin-top: 20px

    &-label
      height: 50px
      white-space: nowrap
    &-input
      height: 50px

      input
        border: 1px solid $default-purple

  .alert-tip
    font-size: 0.8rem
</style>
